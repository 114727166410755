import React, { FormEvent, useEffect, useState, FC } from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Page } from '../../Page';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Button from '@material-ui/core/Button';
import { Location } from '../../../../entities/Entities';
import { FormLabel } from '@material-ui/core';
import { RouteComponentProps, Link, useHistory } from 'react-router-dom';
import {
  locationGetByIdEndpoint,
  locationUpdateByIdEndpoint,
} from '../../../../api/Endpoints';
import { LoadingState } from '../../../../LoadingState';
import {
  adminLocationEdit,
  adminLocationView,
} from '../../../../api/PageLinks';
import { AdminTopSideBar } from '../../../../components/AdminTopSideBar';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    name_input: {
      width: '300px',
    },
  }),
);

interface RouteParams {
  locationId: string;
}

export const LocationEditPage: FC<RouteComponentProps<RouteParams>> = ({
  match,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<LoadingState>(LoadingState.Loading);
  const [submited, setSubmitted] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const loadLocationData = async () => {
    let tempResults: string[][] = [];

    //Fetch data from endpoint
    await fetch(locationGetByIdEndpoint + match.params.locationId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + Cookies.get('token'),
      },
    })
      .then((res) => res.json())
      .then((jsonData) => {
        let location: Location = jsonData;

        //Fill in form with location data
        setId(location.id.toString());
        setName(location.name);
        setDescription(location.description);
        setLoading(LoadingState.Loaded);
      })
      .catch((err) => {
        console.error(err);
        setLoading(LoadingState.Failed);
      });
  };
  const history = useHistory();
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (submited === false) {
      setSubmitted(true);
      fetch(locationUpdateByIdEndpoint + id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + Cookies.get('token'),
        },
        // We convert the React state to JSON and send it as the POST body
        body: JSON.stringify({ name: name, description: description }),
      }).then(function (response) {
        console.log(response);
        return response.json();
      });
      history.push(adminLocationView + id);
    }
  };

  useEffect(() => {
    loadLocationData();
  }, []);

  const renderSwitch = () => {
    switch (loading) {
      case LoadingState.Loading:
        return <div>Loading</div>;
      case LoadingState.Loaded:
        return (
          <form
            className={classes.root}
            autoComplete="off"
            onSubmit={handleSubmit}
            css={css`
              list-style: none;
              margin: 10px 0 0 0;
              padding: 20px 20px;
              background-color: #fff;
              border-radius: 8px 8px;
              box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
            `}
          >
            <FormLabel>{'Location #' + id}</FormLabel>
            <div>
              <TextField
                required
                id="location-name-field"
                label="Name"
                defaultValue={name}
                style={{ width: '400px', maxWidth: '100%' }}
                type="text"
                onChange={(evt) => setName(evt.target.value)}
              />
            </div>
            <br />
            <div>
              <TextField
                id="location-description-field"
                label="Description"
                defaultValue={description}
                variant="outlined"
                multiline={true}
                rows={5}
                style={{ width: '100%' }}
                onChange={(evt) => setDescription(evt.target.value)}
              />
            </div>
            <br />
            <Button variant="contained" type="submit">
              Save changes
            </Button>
          </form>
        );
      default:
        return <div>Failed to load</div>;
    }
  };

  return (
    <div>
      <AdminTopSideBar />
      <Page title="Location edit">{renderSwitch()}</Page>
    </div>
  );
};
