import React, { FC, useEffect, useState } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { gray2, gray3, gray6 } from '../Styles';
import { Link } from 'react-router-dom';
import { Hunt, Creature, Location } from '../entities/Entities';
import { creatureImgUrl } from '../api/PageLinks';
import { creatureGetByIdEndpoint, locationGetByIdEndpoint } from '../api/Endpoints';
import Cookies from 'js-cookie';

interface HuntCardProps {
  data: Hunt;
}

export const HuntCard: FC<HuntCardProps> = ({ data }) => {
  const [creatureName, setCreatureName] = useState<string>("");
  const [locationName, setLocationName] = useState<string>("");

  const loadHuntInfo = async () => {
    fetch(creatureGetByIdEndpoint + data.locationCreature.id, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((jsonData) => {
        //console.log(jsonData);
        let creature: Creature = jsonData;
        setCreatureName(creature.name);
      })
      .catch((err) => {
        console.error(err);
      });
      fetch(locationGetByIdEndpoint + data.locationCreature.locationId, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('token'),
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((jsonData) => {
          //console.log(jsonData);
          let location: Location = jsonData;
          setLocationName(location.name);
        })
        .catch((err) => {
          console.error(err);
        });
  };

  useEffect(() => {
    loadHuntInfo();
  }, []);

  return (
    <div
      css={css`
        padding: 10px 20px;
        :hover {
          background-color: ${gray6};
        }
      `}
    >
      <div
        css={css`
          padding: 5px 0px;
          font-size: 12px;
        `}
      >
        {data.dateTime.toString()}
      </div>
      <span
        css={css`
          padding-bottom: 10px;
          font-size: 15px;
          color: ${gray2};
        `}
      >
        <img
          src={creatureImgUrl + data.locationCreature.creatureId + '.png'}
          css={css`
            float: left;
            margin-top: 1em;
            margin-right: 1em;
          `}
        />
        <p>
          {'I caught a ' +
            creatureName +
            ' in ' +
            locationName +
            '! It dropped ' +
            data.locationCreature.gold +
            ' gold and gave me ' +
            data.locationCreature.experience +
            ' experience.'}
        </p>
      </span>
    </div>
  );
};
