import React from 'react';
import userIcon from './resources/images/user.svg';
import editIcon from './resources/images/edit-pencil.svg';
import trashIcon from './resources/images/trash.svg';
import infoIcon from './resources/images/information-solid.svg';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

export const UserIcon = () => (
  <img
    src={userIcon}
    alt="User"
    css={css`
      width: 12px;
      opacity: 0.6;
    `}
  />
);

export const EditIcon = () => (
  <img
    src={editIcon}
    alt="Edit"
    css={css`
      width: 18px;
      opacity: 0.6;
      margin: 5px 5px;
    `}
  />
);

export const TrashIcon = () => (
  <img
    src={trashIcon}
    alt="Delete"
    css={css`
      width: 18px;
      opacity: 0.6;
      margin: 5px 5px;
    `}
  />
);

export const InfoIcon = () => (
  <img
    src={infoIcon}
    alt="Info"
    css={css`
      width: 18px;
      opacity: 0.6;
      margin: 5px 5px;
    `}
  />
);