import React, { FC } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { PageTitle } from '../../components/PageTitle';

interface Props {
  title?: string;
}
export const Page: FC<Props> = ({ title, children }) => (
  <div
    css={css`
      margin: 50px 50px 20px 70px;
      padding: 30px 20px;
      max-width: 90%;
    `}
  >
    {title && <PageTitle>{title}</PageTitle>}
    {children}
  </div>
);
