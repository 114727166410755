import React, { FC } from 'react';
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { EditIcon, TrashIcon, InfoIcon } from '../Icons';
import { PageTitle } from './PageTitle';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface Props {
  tableName?: string;
  data: string[][];
  headers: string[];
  showEditControls?: boolean;
  showDeleteControls?: boolean;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  // heading_cells: {
  //   backgroundColor: '#e8e8e8',
  //   fontWeight: 'bold',
  // },
});

const HeadingCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#69a2ff',
      color: 'black',
      fontWeight: 'bold',
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const DataTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

export const DataTable: FC<Props> = ({
  tableName = '',
  data,
  headers,
  showEditControls = true,
  showDeleteControls = true,
}) => {
  const classes = useStyles();

  const onClickEdit = (itemId: string) => {
    console.log(itemId);
  };

  const onClickDelete = (itemId: string) => {
    console.log(itemId);
  };

  return (
    <div>
      <PageTitle>{tableName}</PageTitle>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="simple table">
          <TableHead>
            <TableRow key={'headings'}>
              {headers.map((column) => (
                <HeadingCell key={'headings_' + column}>
                  <TableSortLabel>{column}</TableSortLabel>
                </HeadingCell>
              ))}
              <HeadingCell align="right" key="headings_actions">
                Actions
              </HeadingCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <DataTableRow key={row[0]}>
                {row.map((column) => (
                  <TableCell align="left" key={row[0] + column}>
                    {column}
                  </TableCell>
                ))}
                <TableCell align="right" key={row[0] + 'actions'}>
                  <Link to={'view/' + row[0]}>
                      <InfoIcon />
                  </Link>
                  <Link to={'edit/' + row[0]}>
                      <EditIcon />
                  </Link>
                  <Link to={'delete/' + row[0]}>
                      <TrashIcon />
                  </Link>
                </TableCell>
              </DataTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
