export const backendEndpoint = 'https://hunt-app-backend.azurewebsites.net/api';
//export const backendEndpoint = 'https://localhost:44306/api';

export const signupEndpoint = backendEndpoint + '/user/signup';
export const loginEndpoint = backendEndpoint + '/user/login';

export const userinfoEndpoint = backendEndpoint + '/user/userinfo';

export const huntEndpoint = backendEndpoint + '/hunt';
export const huntHistoryEndpoint = backendEndpoint + '/hunt/history?amount=';

export const creatureGetAllEndpoint = backendEndpoint + '/creature/all';
export const creatureGetByIdEndpoint = backendEndpoint + '/creature/';
export const creatureUpdateByIdEndpoint = backendEndpoint + '/creature/';
export const creatureAddEndpoint = backendEndpoint + '/creature';

export const locationGetAllEndpoint = backendEndpoint + '/location/all';
export const locationGetByIdEndpoint = backendEndpoint + '/location/';
export const locationUpdateByIdEndpoint = backendEndpoint + '/location/';
export const locationAddEndpoint = backendEndpoint + '/location';