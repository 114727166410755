import React, { FormEvent, useEffect, useState, FC } from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Page } from '../../Page';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Button from '@material-ui/core/Button';
import { Location } from '../../../../entities/Entities';
import { FormLabel } from '@material-ui/core';
import { RouteComponentProps, Link, useHistory } from 'react-router-dom';
import {
  locationAddEndpoint,
} from '../../../../api/Endpoints';
import {
  adminLocationEdit,
  adminLocationView,
  adminLocationTable,
} from '../../../../api/PageLinks';
import { AdminTopSideBar } from '../../../../components/AdminTopSideBar';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    name_input: {
      width: '300px',
    },
  }),
);

export const LocationAddPage = () => {
  const classes = useStyles();

  const [submited, setSubmitted] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const history = useHistory();
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (submited === false) {
      setSubmitted(true);
      fetch(locationAddEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + Cookies.get('token')
        },
        // We convert the React state to JSON and send it as the POST body
        body: JSON.stringify({ name: name, description: description }),
      }).then((res) => res.json())
      .then((jsonData) => {
        let location: Location = jsonData;
        //Fill in form with location data
        history.push(adminLocationView + location.id.toString());
      })
      .catch((err) => {
        console.error(err);
        history.push(adminLocationTable);
      });
    }
  };

  return (
    <div>
      <AdminTopSideBar />
      <Page title="Location add">
        <form
          className={classes.root}
          autoComplete="off"
          onSubmit={handleSubmit}
          css={css`
            list-style: none;
            margin: 10px 0 0 0;
            padding: 20px 20px;
            background-color: #fff;
            border-radius: 8px 8px;
            box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
          `}
        >
          <FormLabel>New location</FormLabel>
          <div>
            <TextField
              required
              id="location-name-field"
              label="Name"
              defaultValue={name}
              style={{ width: '400px', maxWidth: '100%' }}
              type="text"
              onChange={(evt) => setName(evt.target.value)}
            />
          </div>
          <br />
          <div>
            <TextField
              id="location-description-field"
              label="Description"
              defaultValue={description}
              variant="outlined"
              multiline={true}
              rows={5}
              style={{ width: '100%' }}
              onChange={(evt) => setDescription(evt.target.value)}
            />
          </div>
          <br />
          <Button variant="contained" type="submit">
            Save
          </Button>
        </form>
      </Page>
    </div>
  );
};
