import React, { lazy, Suspense } from 'react';
import './App.css';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { fontFamily, fontSize, gray2 } from './Styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

//Pages
import { SignupPage } from './Views/Pages/SignupPage';
import { NotFoundPage } from './Views/Pages/NotFoundPage';
import { CreatureTablePage } from './Views/Pages/Admin/CreaturePages/CreatureTablePage';
import { CreatureDeletePage } from './Views/Pages/Admin/CreaturePages/CreatureDeletePage';
import { CreatureEditPage } from './Views/Pages/Admin/CreaturePages/CreatureEditPage';
import { CreatureViewPage } from './Views/Pages/Admin/CreaturePages/CreatureViewPage';
import { LocationTablePage } from './Views/Pages/Admin/LocationPages/LocationTablePage';
import { LocationEditPage } from './Views/Pages/Admin/LocationPages/LocationEditPage';
import { LocationViewPage } from './Views/Pages/Admin/LocationPages/LocationViewPage';
import { CreatureAddPage } from './Views/Pages/Admin/CreaturePages/CreatureAddPage';
import { LocationAddPage } from './Views/Pages/Admin/LocationPages/LocationAddPage';
import { PrivacyPage } from './Views/Pages/PrivacyPage';
import { LoginPage } from './Views/Pages/LoginPage';
import CampPage from './Views/Pages/User/CampPage';
import PrivateRoute from './components/PrivateRoute';
import { LogoutPage } from './Views/Pages/LogoutPage';
//Lazy loaded pages

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <div
        css={css`
          font-family: ${fontFamily};
          font-size: ${fontSize};
          color: ${gray2};
        `}
      >
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/logout" component={LogoutPage} />
          <Route path="/signup" component={SignupPage} />
          <Route path="/privacy" component={PrivacyPage} />
          <PrivateRoute path="/camp" component={CampPage} />
          <Route exact path="/admin/creatures" component={CreatureTablePage} />
          <Route path="/admin/creatures/view/:creatureId" component={CreatureViewPage}/>
          <Route path="/admin/creatures/edit/:creatureId" component={CreatureEditPage}/>
          <Route path="/admin/creatures/add" component={CreatureAddPage}/>
          <Route path="/admin/creatures/delete/:creatureId" component={CreatureDeletePage}/>
          <Route exact path="/admin/locations" component={LocationTablePage} />
          <Route path="/admin/locations/view/:locationId" component={LocationViewPage}/>
          <Route path="/admin/locations/edit/:locationId" component={LocationEditPage}/>
          <Route path="/admin/locations/add" component={LocationAddPage}/>
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;
