import React, { FC, useEffect, useState, Fragment } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Page } from '../../Page';
import { DataTable } from '../../../../components/DataTable';
import { PageTitle } from '../../../../components/PageTitle';
import { Creature } from '../../../../entities/Entities';
import { AdminTopSideBar } from '../../../../components/AdminTopSideBar';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {
  creatureAddEndpoint,
  creatureGetAllEndpoint,
} from '../../../../api/Endpoints';
import { adminCreatureView, adminCreatureAdd } from '../../../../api/PageLinks';
import Cookies from 'js-cookie';

export const CreatureTablePage = () => {
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [tableResults, setResults] = useState<string[][]>([
    ['a', 'a2', 'a3'],
    ['b1', 'b2', 'b3'],
  ]);
  const [tableHeaders, setHeaders] = useState<string[]>([
    'id',
    'name',
    'description',
  ]);

  const loadTableData = async () => {
    let tempResults: string[][] = [];

    //Fetch data from endpoint
    await fetch(creatureGetAllEndpoint, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((jsonData) => {
        let creatures: Creature[] = jsonData;
        let headers: string[] = [];

        if (creatures.length > 0) {
          //Set table headers
          Object.keys(creatures[0]).forEach((key) => headers.push(key));

          //Populate table results
          creatures.forEach((element) => {
            let elementProperties: string[] = [];
            elementProperties.push(element.id.toString());
            elementProperties.push(element.name);
            elementProperties.push(element.description);
            tempResults.push(elementProperties);
          });
        }

        setResults(tempResults);
        setHeaders(headers);
      })
      .catch((err) => {
        console.error(err);
      });
    setTableLoading(false);
  };

  useEffect(() => {
    loadTableData();
  }, []);

  return (
    <div>
      <AdminTopSideBar />
      <Page title="Creatures">
        {tableLoading ? (
          <p>Loading...</p>
        ) : (
          <Fragment>
            <DataTable headers={tableHeaders} data={tableResults} />
            <br />
            <Link to={adminCreatureAdd} className="link-plain">
              <Button variant="contained">Add new</Button>
            </Link>
          </Fragment>
          //<DataTable tableName="Creatures" headers={} data={} />
        )}
      </Page>
    </div>
  );
};
