export const adminCreatureTable = '/admin/creatures/';
export const adminCreatureView = '/admin/creatures/view/';
export const adminCreatureEdit = '/admin/creatures/edit/';
export const adminCreatureAdd = '/admin/creatures/add/';

export const adminLocationTable = '/admin/locations/';
export const adminLocationView = '/admin/locations/view/';
export const adminLocationEdit = '/admin/locations/edit/';
export const adminLocationAdd = '/admin/locations/add/';

export const loginUrl = '/login/';
export const logoutUrl = '/logout/';
export const signupUrl = '/signup/';

export const campUrl = '/camp/';

export const creatureImgUrl = 'https://generistgames.com/games/hunt-app/img/'