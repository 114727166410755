import React, { useEffect, FC, Fragment } from 'react';
import { Page } from './Page';

export const PrivacyPage = () => {
  useEffect(() => {
    document.title = "Privacy | Creature hunt"
  }, []);

  return (
    <Fragment>
      <Page title="Privacy information">
        <p>
          For each user, the following user data is stored: User ID (number used
          to identify each user internally), User registration date and time,
          Username, Display name , Password validation information (salt, hashed
          password, number of hashing iterations), email address, and other game
          related data. By creating an account, you confirm that you are above
          13 years old.
        </p>
        <p>
          This website is a side project and thus has a lot of missing features.
          If you have any queries, please send an email to
          contact@generistgames.com
        </p>
      </Page>
    </Fragment>
  );
};
