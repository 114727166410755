import React, { useState, FormEvent, useEffect } from 'react';
import { signupUrl, loginUrl, campUrl } from '../../api/PageLinks';
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie';

export const LogoutPage = () => {
  const history = useHistory();

  useEffect(() => {
    Cookies.remove('token');
    Cookies.remove('username');
    history.push(loginUrl);
  }, []);

  return <div>Logging out...</div>;
};
