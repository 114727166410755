import React, { useState, useEffect } from 'react';
import { Page } from '../Page';
import { UserTopSideBar } from '../../../components/UserTopSideBar';
import { Button, Snackbar } from '@material-ui/core';
import {
  huntEndpoint,
  huntHistoryEndpoint,
  userinfoEndpoint,
} from '../../../api/Endpoints';
import Cookies from 'js-cookie';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Hunt, HuntResult, Userinfo } from '../../../entities/Entities';
import { HuntCard } from '../../../components/HuntCard';

export const CampPage = () => {
  const [hunts, setHunts] = useState<Hunt[]>([]);
  const [gold, setGold] = useState<number>(0);
  const [experience, setExperience] = useState<number>(0);
  const [nexthunt, setNextHunt] = useState<string>("");
  const [toastOpen, setToastOpen] = React.useState(false);

  const doHunt = () => {
    fetch(huntEndpoint, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          setToastOpen(true);
          throw new Error('Error code: ' + res.status.toString());
        } else return res.json();
      })
      .then((jsonData) => {
        //console.log(jsonData);
        let huntResult: HuntResult = jsonData;
        setGold(huntResult.userGold);
        setExperience(huntResult.userExperience);
        setHunts((hunts) => [huntResult, ...hunts]);
        setNextHunt((new Date(huntResult.nextHunt)).toString());
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const loadPreviousHunts = async () => {
    fetch(huntHistoryEndpoint + '10', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((jsonData) => {
        //console.log(jsonData);
        let huntResults: Hunt[] = jsonData;
        setHunts(huntResults);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const loadUserinfo = async () => {
    fetch(userinfoEndpoint, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((jsonData) => {
        //console.log(jsonData);
        let userinfo: Userinfo = jsonData;
        setGold(userinfo.gold);
        setExperience(userinfo.experience);
        setNextHunt((new Date(userinfo.nextHuntDateTime)).toString());
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleToastClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  useEffect(() => {
    loadUserinfo();
    loadPreviousHunts();
    document.title = "Base camp | Creature hunt"
  }, []);

  return (
    <div>
      <UserTopSideBar />
      <Page title="Your base camp">
        <label>{'Gold: ' + gold}</label>
        <br />
        <label>{'Experience: ' + experience}</label>
        <br />
        <label>{'Next hunt: ' + nexthunt}</label>
        <br />
        <Button variant="contained" onClick={doHunt}>
          Hunt
        </Button>
        <ul
          css={css`
            list-style: none;
            margin: 10px 0 0 0;
            padding: 0px 0px;
            background-color: #fff;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-top: 3px solid #dbb36a;
            box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
          `}
        >
          {hunts.map((hunt) => (
            <li
              key={hunt.id}
              css={css`
                border-top: 1px solid #e3e2e2;
                :first-of-type {
                  border-top: none;
                }
              `}
            >
              {/* <HuntCard data={hunt} /> */}
              <HuntCard data={{ ...hunt, dateTime: new Date(hunt.dateTime) }} />
            </li>
          ))}
        </ul>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={toastOpen}
          onClose={handleToastClose}
          autoHideDuration={3000}
          message="Unable to hunt!"
        />
      </Page>
    </div>
  );
};

export default CampPage;
