import React, { FormEvent, useEffect, useState, FC } from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Page } from '../../Page';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Button from '@material-ui/core/Button';
import { Creature } from '../../../../entities/Entities';
import { FormLabel } from '@material-ui/core';
import { RouteComponentProps, Link } from 'react-router-dom';
import { creatureGetByIdEndpoint } from '../../../../api/Endpoints';
import { LoadingState } from '../../../../LoadingState';
import { adminCreatureEdit } from '../../../../api/PageLinks';
import { AdminTopSideBar } from '../../../../components/AdminTopSideBar';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    name_input: {
      width: '300px',
    },
  }),
);

interface RouteParams {
  creatureId: string;
}

export const CreatureViewPage: FC<RouteComponentProps<RouteParams>> = ({
  match,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<LoadingState>(LoadingState.Loading);
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const loadCreatureData = async () => {
    let tempResults: string[][] = [];

    //Fetch data from endpoint
    await fetch(creatureGetByIdEndpoint + match.params.creatureId, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + Cookies.get('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((jsonData) => {
        let creature: Creature = jsonData;

        //Fill in form with creature data
        setId(creature.id.toString());
        setName(creature.name);
        setDescription(creature.description);
        setLoading(LoadingState.Loaded);
      })
      .catch((err) => {
        console.error(err);
        setLoading(LoadingState.Failed);
      });
  };

  useEffect(() => {
    loadCreatureData();
  }, []);

  const renderSwitch = () => {
    switch (loading) {
      case LoadingState.Loading:
        return <div>Loading</div>;
      case LoadingState.Loaded:
        return (
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            css={css`
              list-style: none;
              margin: 10px 0 0 0;
              padding: 20px 20px;
              background-color: #fff;
              border-radius: 8px 8px;
              box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
            `}
          >
            <FormLabel>{'Creature #' + id}</FormLabel>
            <Link to={adminCreatureEdit + id}>
              <Button
                variant="contained"
                css={css`
                  float: right;
                `}
              >
                Edit
              </Button>
            </Link>
            <div>
              <TextField
                required
                id="creature-name-field"
                label="Name"
                value={name}
                style={{ width: '400px', maxWidth: '100%' }}
                type="text"
                disabled={true}
              />
            </div>
            <br />
            <div>
              <TextField
                id="creature-description-field"
                label="Description"
                value={description || ''}
                variant="outlined"
                multiline={true}
                rows={5}
                style={{ width: '100%' }}
                disabled={true}
              />
            </div>
          </form>
        );
      default:
        return <div>Failed to load</div>;
    }
  };

  return (
    <div>
      <AdminTopSideBar />
      <Page title="Creature info">{renderSwitch()}</Page>
    </div>
  );
};
